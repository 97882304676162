import React, { useContext } from 'react';
import { currentContext } from '../../App';
import { sessionStorage } from 'utils/utils';
import { HOME_PRODUCT_CLASSIFY, HOME_PRODUCT_SPECIALS_CLASSIFY } from 'pages/Product/constants.js'
import './index.less';

function Menu(props) {
    const { type = 'top'} = props;
    const { cover } = useContext(currentContext);
    return (
        <div className={`Menu ${type==='bottom'?'bottomMenu':''}`}>
            <ul>
                {type === 'top' && <li>
                    <a className="menu-title" href={"/" }>
                        <span className="title-text">{sessionStorage.get('siteName')==='中文'?"首页":"Home"}</span>
                    </a>
                </li>}
                {type === 'top' && <li>
                    <div className="menu-title">
                        <span className="title-text">
                        Specials Offers
                            <i className="unfold"></i>
                        </span>
                    </div>
                    <div className="sub-item-cointer">
                        {Object.keys(HOME_PRODUCT_CLASSIFY).map((subItem)=>{
                            return <a className="sub-item" key={subItem} href={"/SpecialsOffers?type=" + subItem}>
                                {HOME_PRODUCT_CLASSIFY[subItem].name}
                            </a>;
                        })}
                        <a className="sub-item" href={"/ProductIntroduction"}>
                            CRO&CMO
                        </a>
                    </div>
                </li>}
                {type === 'top' && <li>
                    <div className="menu-title">
                        <span className="title-text">
                            Product
                            <i className="unfold"></i>
                        </span>
                    </div>
                    <div className="sub-item-cointer">
                        {Object.keys(HOME_PRODUCT_SPECIALS_CLASSIFY).map((subItem)=>{
                            return <a className="sub-item" key={subItem} href={"/Product?type=" + subItem}>
                                {HOME_PRODUCT_SPECIALS_CLASSIFY[subItem].name}
                            </a>;
                        })}
                    </div>
                </li>}
                {type === 'bottom' && <li>
                    <div className="menu-title">
                        <a className="title-text" href={"/Product"}>
                            {sessionStorage.get('siteName')==='中文'?"产品中心":'Product'}    
                        </a>
                    </div>
                </li>}
                {cover && cover.directories && cover.directories.map((item, index) => (type === 'bottom'&&item.bottomShow || type === 'top') && <li key={index}>
                    
                    {
                        type === 'top' && item.isShowContent && <a className="menu-title" href={"/commonPage/1/" + item.title}>
                            <span className="title-text">
                                {item.title}
                                {!item.isShowContent && <i className="unfold"></i>}
                            </span>
                        </a>
                    }
                    {
                        type === 'top' && !item.isShowContent && <div className="menu-title">
                            <span className="title-text">
                                {item.title}
                                {!item.isShowContent && <i className="unfold"></i>}
                            </span>
                        </div>
                    }
                    {
                        type === 'bottom' && <div className="menu-title">
                            <a className="title-text"  href={"/commonPage/2/" + item.title + '/' + item.subDirectories[0].title} >
                                {item.title}
                            </a>
                        </div>
                    }
                        
                    {
                        type === 'top' && !item.isShowContent && <div className="sub-item-cointer">
                            {item.subDirectories.map((subItem)=>{
                                return <a className="sub-item" key={subItem.key} href={"/commonPage/2/" + item.title + '/' + subItem.title}>
                                    {subItem.title}
                                </a>;
                            })}
                        </div>
                    }
                        
                    {/* {
                        type === 'bottom' && <div className="sub-item-cointer">
                            <a className="sub-item" href={"/commonPage/1/" + item.title}>
                                {item.title}
                            </a>
                        </div>
                    } */}
                    
                </li>)}
                {type === 'top' && <li>
                    <a className="menu-title" href={"/ContactUs" }>
                        <span className="title-text">
                            {sessionStorage.get('siteName')==='中文'?"联系我们":'Contact Us'}    
                        </span>
                    </a>
                </li>}
                {type === 'bottom' && <li>
                    <div className="menu-title">
                        <a className="title-text" href={"/ContactUs"}>
                            {sessionStorage.get('siteName')==='中文'?"联系我们":'Contact Us'}    
                        </a>
                    </div>
                </li>}
                {type === 'top' && <li>
                    <a className="menu-title" href={"/News" }>
                        <span className="title-text">
                            {sessionStorage.get('siteName')==='中文'?"新闻动态":'News'}    
                        </span>
                    </a>
                </li>}
                {type === 'bottom' && <li>
                    <div className="menu-title">
                        <a className="title-text" href={"/News"}>
                            {sessionStorage.get('siteName')==='中文'?"新闻":'News'}    
                        </a>
                    </div>
                </li>}
            </ul>
        </div>
    );
}

export default Menu;