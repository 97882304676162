import React, { useContext, useRef,useState } from 'react';
import { currentContext } from '../../App';
import './index.less';

function SearchBox(props) {
    let { type = 'top' } = props.config ? props.config : {};
    const { title = '' } = props
    const { searchPlaceHolder } = useContext(currentContext)
    const searchEl = useRef(null);
    const [value,setValue] = useState(decodeURI(title))

    const searchAction = () => {
        if(!searchEl.current.value && type === 'top'){
            return
        }
        window.location.href = '/Product?title=' + searchEl.current.value;
    }
    const inputChange = (e)=>{
        setValue(e.target.value)
    }
    const keyDown = (e)=>{
        if(e.keyCode === 13){
            searchAction()
        }
    }
    return (
        <div className={"search-box " + type} onKeyDown={keyDown}>
            <div className="search-btn-icon" onClick={searchAction}></div>
            <input  className="search-box" ref={searchEl} value={value} onChange={inputChange} placeholder={searchPlaceHolder} />
            {type==='top' && <div className="search-btn" onClick={searchAction}></div>}
        </div>
    );
}

export default SearchBox;