export const HOME_PRODUCT_CLASSIFY = {
  '0':{
    name: 'Selected Products',
    homeDesc: 'Regularly Updated Choices',
    pic: 'https://files.jhechem.com/20061-20201115133601876-jh-1.jpeg',
    desc: 'In this section “Selected Products”, you can find the industrial chemicals and laboratory chemicals which we own the greatest advantages. The selected products we provide are of the best quality and the most competitive prices, please do not hesitate to place an order online or send us an inquiry for more details.'
  },
  '2':{
    name: 'Featured Products',
    homeDesc: 'Advanced  Product Lines',
    pic: 'https://files.jhechem.com/20061-20201115133822619-jh-2.jpeg',
    desc: 'In this section “Featured Products”, J&H Chem provides carefully selected series of chemical products for our loyal customers. The themes of featured products will be changed after a period of time, according to the popularity. Customers can follow our home page to receive the updates on our new featured products and other events.'
  },
  '1':{
    name: 'J&H NOVA',
    homeDesc: 'New & Innovative Products',
    pic: 'https://files.jhechem.com/20061-20201115133839227-jh-3.jpeg',
    desc: `J&H Chem Nova is a program that accepts and showcases the custom synthesis projects from the companies that require a molecule to be made exclusively for them at a certain scale. In this section, you can find the ready products synthesized by J&H Chem for our customers such as virtual pharma companies and small biotechs who don't have the in-house resources to do the chemistry. We provide J&H Chem Nova for customers that are in need of custom synthesis projects. Custom synthesis can be as low as a few milligrams, or as high as 100 kilos or more.`
  }
}

export const HOME_PRODUCT_SPECIALS_CLASSIFY = {
  '0':{
    name: 'Intermediates'
  },
  '1':{
    name: 'Fluorochemicals'
  },
  '2':{
    name: 'Biochemicals'
  },
  '3':{
    name: 'APIs'
  },
  '4':{
    name: 'OLED Materals'
  },
  '5':{
    name: 'Silicones'
  },
  '6':{
    name: 'Other Chemicals'
  },
}
