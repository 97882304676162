import React, { useState, useEffect } from 'react';
import { Pagination } from 'antd'
// import { currentContext } from '../../App';
import { HOME_PRODUCT_CLASSIFY } from 'pages/Product/constants.js'
import { get } from 'utils/request';
import SearchBox from '../../elements/SearchBox';
import Title from '../../elements/Title';
import ProductList from '../../components/ProductList';
// import Enquiry from '../../components/Enquiry';
import './SpecialsOffers.less';

export default function Product(props) {
    const urlParamsArr = window.location.href.split("?")[1] && window.location.href.split("?")[1].split("&") || [];
    const urlParamsObj = {}
    urlParamsArr.forEach((item)=>{
        const [key, value] = item.split('=')
        urlParamsObj[key] = value
    })
    const { title, type } = urlParamsObj
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(16);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    useEffect(()=> {
        if(!type){
            setLoading(true)
            get('/v2/product/search', {
                word: title
            }).then(data => {
                if (data.code === 0 && data.res && data.res.length > 0) {
                    setList(data.res);
                }
            }).finally(()=>{
                setLoading(false)
            })
        }
    },[])

    useEffect(()=> {
        if(type){
            setLoading(true)
            get(`/v2/product/home/classify/${type}?page=${page}&limit=${limit}`).then(({res}) => {
                const { items, total } = res
                setTotal(total)
                const list = items.map((innerRes)=>{
                    return {
                        ...innerRes,
                        attrs:Object.keys(innerRes)
                        .filter(item=>item!=='name' && item!=='pic' && item!=='id')
                        .map(key=>({
                            name:key,
                            value:innerRes[key]
                        }))
                    }
                })
                setList(list)
            }).finally(()=>{
                setLoading(false)
            })
        }
    },[limit,page])

    function handlePaginationChange({page, pageSize}){
        setLimit(pageSize)
        setPage(page)
    }

    return (
        <div className='Product'>
            <div className='sub-menu-container'>
                <div className="side-sub-menu">
                    <div className='sub-title-item'>Specials Offers</div>
                    {Object.keys(HOME_PRODUCT_CLASSIFY).map((key)=>{
                        return <div className='sub-title-item' key={key}>
                            <a href={`SpecialsOffers?type=${key}`}>{HOME_PRODUCT_CLASSIFY[key].name}</a>
                        </div>
                    })}
                    <div className='sub-title-item'>
                        <a href="/ProductIntroduction">CRO&CMO</a>
                    </div>
                </div>
                <div className="sub-content">
                    <div className='product-detail'>
                        <div className="container-product-title">
                            <Title cnTitle1 = 'J&H' cnTitle2 = '产品' enTitle1 = 'J&H' enTitle2 = {(HOME_PRODUCT_CLASSIFY[type] && HOME_PRODUCT_CLASSIFY[type].name) || 'Product'} position='left'/>
                        </div>
                        {HOME_PRODUCT_CLASSIFY[type].desc && <div className='desc-bar'>
                            {HOME_PRODUCT_CLASSIFY[type].desc}
                        </div>}
                        <div className='search-bar'>
                            <SearchBox config={{type: 'content'}} title={title || ''}/>
                        </div>
                        <div className='product-list'>
                            <ProductList list={list} type='style2' loading={loading}/>
                            { !type && !loading && <div className='count'>{list.length} Records</div> }
                        </div>
                        { type && !loading && <div className="pagination-container">
                            <Pagination 
                                defaultCurrent={1} 
                                showQuickJumper 
                                current={page} 
                                pageSize={limit} 
                                total={total} 
                                pageSizeOptions={[16,20,40,80]}
                                onChange={(page, pageSize)=>{
                                    handlePaginationChange({page, pageSize})
                                }}
                            />
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}