import React, { useContext } from 'react';
import SiteChoose from 'elements/SiteChoose';
import Menu from 'elements/Menu';
import SearchBox from 'elements/SearchBox';
import { currentContext } from 'App';
import { getImgUrl } from 'utils/utils';
import './index.less';

function Header() {
  return (
    <div className="Header">
      <Head />
      <Top />
      <Menu />
    </div>
  );
}

function Head() {
    const { welcome } = useContext(currentContext);
    return (
        <div className="head-container">
            <div className="head">
                {welcome}
                <div className="language">
                    <SiteChoose />
                </div>
            </div>
        </div>
        
    );
}

function Top() {
    const { logo } = useContext(currentContext);
    return (

        <div className="top-container">
            <div className="top">
                <div className="search">
                    <SearchBox />
                </div>
                <img className="top-logo" src={getImgUrl(logo)} alt="" />
            </div>
        </div>
    );
}

export default Header;
