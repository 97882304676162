import React, { useEffect, useRef } from 'react';

import './index.less';

//背景绘制函数
function drawBg(cvs,wW,wH)
{
  cvs.beginPath();
  // cvs.fillStyle="#020215";
  cvs.fillStyle="rgba(11,162,232,1)";
  cvs.fillRect(0,0,wW,wH);
  cvs.save();
}

//每个粒子的配置
function Dot(wW,wH)
{
  this.color = "rgba(255, 255, 255, 0.9)";//创建颜色
  //圆心坐标
  this.x = Math.round(Math.random()*wW);
  this.y = Math.round(Math.random()*wH);
  //速度(不同方向)
  this.vx = (Math.random()-0.5)*4;
  this.vy = (Math.random()-0.5)*4;
  //随机半径
  this.radius = Math.round(Math.random()*5);
  // this.radius = 2;
}
//初始化
Dot.prototype.draw = function(cvs) {
  cvs.beginPath();
  cvs.fillStyle = this.color;
  cvs.arc(this.x,this.y,this.radius,0,360,false);
  cvs.fill();
};

//画出粒子
function drawDots(Dots,wW,wH,cvs)
{
  drawBg(cvs,wW,wH);
  for(var i=0;i<Dots.n;i++)
  {
    Dots.array[i].draw(cvs);
  }
}

//移动粒子
function moveDots(Dots,wW,wH){      	
  for(var i=0;i<Dots.n;i++)
  {
    var dot = Dots.array[i];
    //反弹判断
    if(dot.x <0 || dot.x>wW)
      {
        dot.vx=-dot.vx;
      }
  if(dot.y <0 || dot.y>wH)
    {
      dot.vy=-dot.vy;
    }
    dot.x += dot.vx;
    dot.y += dot.vy;
  }
}

//连线
function connect(Dots, cvs) {
  for(var i=0;i<Dots.n;i++)
  {     		
    for(var j=0;j<Dots.n;j++)
    {
      var dot1 = Dots.array[i];
      var dot2 = Dots.array[j];
      var color="rgba(255, 255, 255, 0.9)";
      if(Math.abs(dot1.x-dot2.x)<Dots.minDis && Math.abs(dot1.y-dot2.y)<Dots.minDis)
      {
        cvs.lineWidth=0.2;
        cvs.beginPath();
        cvs.strokeStyle=color;
        cvs.moveTo(dot1.x,dot1.y);
        cvs.lineTo(dot2.x,dot2.y);
        cvs.stroke();
      }
    }
  }
}

const requestAnimFrame = ( function() {
  return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function( callback ) {
          window.setTimeout( callback, 1000 / 60 );
        };
})();

function AnimationBox(props) {
  const curCanvas = useRef()

  useEffect(()=>{
    const can = curCanvas.current;
    const wW = window.innerWidth;
    const wH = window.innerHeight/5*4;      
    can.width = wW;
    can.height = wH;      

    const cvs=can.getContext("2d");

    if(cvs){
      //绘制背景     
      drawBg(cvs,wW,wH);

      //创建粒子配置（总体）,静态类
      var Dots= {
        n:100,
        minDis:110,
        d_mouse:100,
        array:[],
        radiusArr:[]
      }

      //创建粒子并放入数组
      for(var i=0;i<Dots.n;i++)
      {
        var dotObj = new Dot(wW,wH);
        Dots.array.push(dotObj);
        Dots.radiusArr.push(dotObj.radius);
      }

      drawDots(Dots,wW,wH,cvs)

      function infinateDot(){
        cvs.clearRect(0,0,wW,wH);
        moveDots(Dots,wW,wH)
        drawDots(Dots,wW,wH,cvs)
        connect(Dots, cvs);
        requestAnimFrame(infinateDot)
      }

      infinateDot()
    }
  },[])

  return (
    <div className="container">
      <canvas ref={curCanvas} />
      <div className="title">HAVE CHEMICAL NEEDS?</div>
      <div className="desc">J&H CHEM is your one stop for all APIs, Intermediates, Building Blocks,Biologics and Custom Synthesis etc. Our Process Technology Centers and highly flexible GMP-compliant production assets is dedicated to delivering successful outcomes for your unique project requirements</div>
    </div>
    
  );
}

export default AnimationBox;
