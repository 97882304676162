import React, { useState, useEffect } from "react";
import { getImgUrl } from 'utils/utils';
import { get, post } from "../../utils/request";
import "./ProductDetail.less";

let codeId
export default function ProductsDetail() {
    const pathArr = window.location.href.split("/");
    const id = pathArr[pathArr.length - 1];
    const [formdata, setFormdata] = useState({});
    const [codeImage, setCodeImage] = useState('');
    const [data, setData] = useState({id});
    const [topAttrs, setTopAttrs] = useState([]);
    const [bottomAttrs, setBottomAttrs] = useState([]);
    
    const [enquiries, setEnquiries] = useState([]);
    function getVerify(){
        get("/v2/inquire/verify", null).then(data => {
            if(data.code===0){
                codeId = data.res.id
                setCodeImage('data:image/png;base64,'+data.res.img)
            }
        });
    }
    useEffect(() => {
        get("/v2/inquire", null).then(data => {
            if (data.code === 0 && data.res && data.res.length > 0) {
                setEnquiries(data.res);
            }
        });
        getVerify()
    }, []);
    useEffect(() => {
        // 兼容传入id的方式
        const { id } = data
        if(id){
            get("/v2/product/" + id, null).then(({res}) => {
                setData(res)
            });
        }
    }, []);
    useEffect(() => {
        const topKeys = ['Product No.', 'Cas No.', 'Synonyms', 'Molecular weight', 'Molecular formula']
        const attrs = data.attrs || []
        const topAttrs = attrs.filter(item=>topKeys.includes(item.name))
        const bottomAttrs = attrs.filter(item=>!topKeys.includes(item.name))
        setTopAttrs(topAttrs)
        setBottomAttrs(bottomAttrs)
    },[data])
    function sendAction() {
        // if (formdata.code != code.id) {
        //     alert("验证码错误！");
        //     return false;
        // }
        // todo: 请求未完成
        post("/v2/inquire", {...formdata,id:String(codeId)}).then(data => {
            alert(data.tips)
        });
    }
    function resetAction() {
        let formObject = {};
        for (let i = 0, dataL = enquiries.length; i < dataL; i++) {
            formObject[enquiries[i].key] = "";
            // setFormdata({...formdata, [enquiries[i].key]: ''});
        }
        formObject.code = "";
        setFormdata(formObject);
    }
    return (
        <div className="ProductsDetail">
            <h1 className="product-title">
                J&H <span>Product</span>
            </h1>
            <div className="product-detail">
                <div className="attrs">
                    <p className="attrs-title">Product Name:{data.name}</p>
                    {topAttrs.map((attr, index) => {
                        return (
                            <p className="attr" key={index}>
                                <span className="attr-name">{attr.name}: </span>
                                {attr.value}
                            </p>
                        );
                    })}
                </div>
                <div className="img-box">
                    <img src={getImgUrl(data.pic)} alt="" />
                </div>
            </div>
            <div className="product-detail">
                <div className="attrs desc-attrs">
                    <p className="attrs-title desc-title">Product Details</p>
                    {bottomAttrs.map((attr, index) => {
                        return (
                            <p className={"attr "+(index%2===0?"attr-bg":'')} key={index}>
                                <span className="attr-name">{attr.name}: </span>
                                {attr.value}
                            </p>
                        );
                    })}
                </div>
            </div>
            
            <div className="inquiries-box">
                <h1>Inquiry</h1>
                <form className="enquiry-items-box">
                    {enquiries.map((item,index) => {
                        return (
                            <div className="item" key={index}>
                                <label className="item-name">{item.displayName}：</label>
                                {item.type === "input" && (
                                    <input
                                        type="text"
                                        className="enquiry-item-input"
                                        key={item.key}
                                        onChange={e => {
                                            setFormdata({
                                                ...formdata,
                                                [item.key]: e.target.value,
                                            });
                                        }}
                                    />
                                )}
                                {item.type === "textarea" && (
                                    <textarea
                                        className="enquiry-item-textarea"
                                        key={item.key}
                                        onChange={e => {
                                            setFormdata({
                                                ...formdata,
                                                [item.key]: e.target.value,
                                            });
                                        }}
                                    ></textarea>
                                )}
                            </div>
                        );
                    })}
                    <div className="item code">
                        <label className="item-name">Code：</label>
                        <input
                            type="text"
                            className="enquiry-item-input"
                            onChange={e => {
                                setFormdata({ ...formdata, code: e.target.value });
                            }}
                        />
                        <img onClick={getVerify} className="code-img" src={codeImage} alt="" />
                    </div>
                    <div className="btns">
                        <input type="button" onClick={sendAction} value="Submit" />
                        <input type="reset" onClick={resetAction} value="Reset" />
                    </div>
                </form>
            </div>
        </div>
    );
}
