import React, { useContext } from 'react';
import { HomeContext, currentContext } from 'App';
import { getImgUrl, sessionStorage } from 'utils/utils';
import './index.less';

function SiteChoose() {
    const {homeData:sites} = useContext(HomeContext);
    const curSites = useContext(currentContext);
    function changeSite(siteInfo){
        sessionStorage.set('siteName',siteInfo.name);
        setTimeout(()=>{
            window.location.href="/";
        },100)
        
    }
    return (
        sites.length > 0 && <div className="site-box">
            <div className="current-site">
                <img src={getImgUrl(curSites.icon)} alt="" />
                <span>{curSites.name}</span>
            </div>
            <ul className={"sites-container"}>
                {sites.map(siteItem => <li key={siteItem.id} className="site-item" onClick={()=>{
                    changeSite(siteItem);
                }}>
                    <img src={getImgUrl(siteItem.icon)} alt="" />
                    <span>{siteItem.name}</span>
                </li>)}
            </ul>
        </div>
    );
}

export default SiteChoose;