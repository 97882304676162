import React, { useState, useEffect, createContext, useContext } from 'react';
import { get } from 'utils/request';
import Header from 'components/Header';
import Menu from 'elements/Menu';
import Router from 'Router';
import { sessionStorage, getFileContent } from 'utils/utils';
import './App.less';

const HomeContext = createContext([]);
const currentContext = createContext({});

function App() {
  const [homeData, setHomeData] = useState([]);
  const [currentData, setCurrentData] = useState({});
  
  useEffect(() => {
    get('/v2/home')
    .then(data => {
        if (data.code === 0 && data.res && data.res.length > 0) {
          const curSiteName = sessionStorage.get('siteName');
          //关闭窗口前默认站点类型不变
          if(!curSiteName){
            setCurrentData(data.res[0]);
            sessionStorage.set('siteName',data.res[0].name);
          }else{
            const curSite = data.res.find(item=>item.name === curSiteName);
            setCurrentData(curSite);
          }
          //!关闭窗口前默认站点类型不变
          setHomeData(data.res);
        }
    })
  }, [])
  
  return homeData.length>0 && (
    <div className="App">
      <HomeContext.Provider value={{
        homeData
      }}>
          <currentContext.Provider value={currentData}>
            <Header />
            <div className="main">
                <Router />
            </div>
            {/* <div className="contact-box">
              <Contact />
            </div> */}
            <Menu type="bottom"/>
            <Footer />
          </currentContext.Provider>
      </HomeContext.Provider>    
    </div>
  );
}

function Footer() {
    const { copyright } = useContext(currentContext);
    const [copyrightContent,setCopyrightContent] = useState('')
    useEffect(() => {
      getFileContent(copyright).then((res)=>{
        if(res.res){
          setCopyrightContent(res.res.content)
        }
      }) 
    }, [copyright])
    return (
  
        <div className="Footer" dangerouslySetInnerHTML={{__html:copyrightContent+'<span><a href="https://beian.miit.gov.cn/state/outPortal/loginPortal.action#/Integrated/index">浙ICP备12045108号</a></span>'}}></div>
        
        );
}

export { HomeContext, currentContext };
export default App;
