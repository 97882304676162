import React from 'react';
import { sessionStorage,getImgUrl } from 'utils/utils';
import './index.less';

function NewsList(props) {
    const { list=[] ,type = 'default',limit = 3} = props;
    if(list.length===0){
        return <div>

            {sessionStorage.get('siteName')==='中文'?"暂无新闻":'No news'}
        </div>
    }
    return (
        <div className="NewsList">
            {type==='default' && <ul className="news-list">
                {list.map((item) => <li className="news-item" key={item.id}>
                    <span className='time'>{item.updatedTime.replace('T','/')}</span>
                    <span style={{color:'#f37306'}}>◆</span>
                    {/* <a href="/new_detail_cn/id/4.html" target="_blank">{item.title}</a> */}
                    <a href={"/NewsDetail/" + item.id } target="_blank" rel="noopener noreferrer" >{item.title}</a>
                    {/* <span className="title"><a target="_blank" rel="noopener noreferrer" href={"/NewsDetail/" + item.id + '?data=' + encodeURIComponent(JSON.stringify(item))}>{item.title}</a></span>
                    <span className="time">{item.time}</span> */}
                </li>)}
            </ul>}
            {type==='img' && <ul className="news-list type-img">
                {list.map((item,index) => index<limit && <li className="news-item" key={item.id}>
                    <img className="news-cover" src={getImgUrl(item.imgUrl)} alt="" />
                    <div className='author'>{item.author},<span className='time'>{item.updatedTime && item.updatedTime.replace('T','/')}</span></div>
                    <p className='news-title'><a href={"/NewsDetail/" + item.id } target="_blank" rel="noopener noreferrer">{item.title}</a></p>
                    <div className="news-desc">
                        {item.desc}
                    </div>
                </li>)}
            </ul>}
        </div>
    );
}

export default NewsList;