import React, { useState, useEffect } from "react";
import { HOME_PRODUCT_CLASSIFY } from 'pages/Product/constants.js'
import './SpecialsOffers.less';

export default function ProductIntroduction() {

    const content = `<div>
    <p><span style="font-size:24px;">CRO&amp;<span style="color:#ff5010;">CMO</span></span></p>
    <p class="p">We are a Contract Manufacturing Organization (CMO) in Chemistry and Biotechnology</p>
    <p class="p"> </p>
    <p class="p">A contract manufacturing organization (CMO), sometimes called a contract development and manufacturing organization (CDMO), is a company that serves other companies in the pharmaceutical industry on a contract basis to provide comprehensive services from drug development through drug manufacturing. This allows major pharmaceutical companies to outsource those aspects of the business, which can help with scalability or can allow the major company to focus on drug discovery and drug marketing instead.</p>
    <p class="p"> </p>
    <p class="p">Services offered by CMOs include, but are not limited to: pre-formulation, formulation development, stability studies, method development, pre-clinical and Phase I clinical trial materials, late-stage clinical trial materials, formal stability, scale-up, registration batches and commercial production. CMOs are contract manufacturers, but they can also be more than that because of the development aspect.</p>
    <p class="p"> </p>
    <p class="p">Outsourcing to a CMO allows the pharmaceutical client to expand its technical resources without increased overhead. The client can then manage its internal resources and costs by focusing on core competencies and high-value projects while reducing or not adding infrastructure or technical staff. Virtual and specialty pharmaceutical companies are particularly well-suited to CDMO partnerships, and big pharmaceutical companies are beginning to view relationships with CDMOs as strategic rather than tactical. With two-thirds of pharmaceutical manufacturing being outsourced, and preferred providers receiving the lion's share, additional demand is being placed on specialty areas, i.e. specialty dosage forms.</p>
    <p class="p"> </p>
    <p class="p">Project Execution</p>
    <p class="p"> </p>
    <p class="p">I. CDMO built to service both development &amp; commercial customers</p>
    <p class="p">II. Sales focused on business relationship</p>
    <p class="p">III. Project Management focused on successful development &amp; tech transfers</p>
    <p class="p">IV. Smooth transfer from development phase to commercial</p>
    <p class="p">V. Client Services/Supply Chain focused on commercial supply</p>
    <p class="p"> </p>
    <p class="p">We are a Contract Research Organization (CRO) in Pharmaceutical and Biotechnology Industries</p>
    <p class="p"> </p>
    <p class="p">A Contract Research Organisation, also called Clinical Research Organization (CRO) is a service organization that provides support to the pharmaceutical and biotechnology industries in the form of outsourced pharmaceutical research services (for both drugs and medical devices). CROs range from large, international full service organizations to small, niche specialty groups and can offer their clients the experience of moving a new drug or device from its conception to FDA marketing approval without the drug sponsor having to maintain a staff for these services.</p>
    <p class="p">JH CHEM provides a one-stop, and wide range of solutions in custom synthesis, supported by world-class analytical services. The result is quick, safe and efficient scale-up. Whether it's developing a new process or improving an existing synthetic route, JH CHEM can make impact in the following areas:</p>
    <p class="p"> </p>
    <p class="p">I. Reducing the number of synthetic steps and costs</p>
    <p class="p">II. Increasing process efficiency, yield and throughput</p>
    <p class="p">III. Replacing dangerous or environmentally unsuitable chemistries</p>
    <p class="p">IV. Working with complex molecules and multi-step syntheses</p>
    <p class="p">V. Developing and optimizing existing processes to produce syntheses amenable to commercial manufacturing</p>
    <p class="p"> </p>
</div>`

    return <div className="Product">
            <div className='sub-menu-container'>
            <div className="side-sub-menu">
                <div className='sub-title-item'>Product</div>
                {Object.keys(HOME_PRODUCT_CLASSIFY).map((key)=>{
                    return <div className='sub-title-item' key={key}>
                        <a href={`SpecialsOffers?type=${key}`}>{HOME_PRODUCT_CLASSIFY[key].name}</a>
                    </div>
                })}
                <div className='sub-title-item'>
                    <a href="/ProductIntroduction">CRO&CMO</a>
                </div>
            </div>
            <div className="sub-content">
                <img style={{width:'100%'}} src="https://files.jhechem.com/20061-20201115143443902-jh-4.jpeg" />
                <div style={{
                    width:'100%',
                    color: '#777',
                    fontSize: '16px',
                    fontWeight: '400',
                }} dangerouslySetInnerHTML = {{ __html: content }}></div>
                <img style={{width:'100%'}} src="https://files.jhechem.com/20061-20201115143655166-jh-5.jpeg" />
            </div>
        </div>
    </div>
}
