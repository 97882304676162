import React, { useState, useEffect } from "react";
import { get } from "utils/request";
import { getFileContent, getImgUrl } from "utils/utils";
import Title from 'elements/Title';
import "./NewsDetail.less";

function NewsDetail(props) {
    // const data = JSON.parse(decodeURIComponent(window.location.search.split('?')[1].split('=')[1]));
    const pathArr = window.location.href.split("/");
    const id = pathArr[pathArr.length - 1];

    const [detailData, setDetailData] = useState({});
    useEffect(() => {
        if (id) {
            const requestUrl = "/v2/news/" + id;
            get(requestUrl, {
                id: id,
            }).then(data => {
                if (data.code === 0 && data.res) {
                    let resule = data.res;
                    let callback = () => {
                        setDetailData(data.res);
                    };
                    getFileContent(resule.content).then(res => {
                        if (res.res && res.res.content) {
                            resule.content = res.res.content;
                        }
                        callback();
                    });
                }
            });
        }
    }, [id]);
    return (
        <div className="NewsDetail">
            <div className="container-news-detail-title">
                <Title cnTitle1 = '新闻' cnTitle2 = '动态' enTitle1 = 'News' enTitle2 = 'Events' position='left'/>
            </div>
            <div className="detail">
                <h2 className="news-title">{detailData.title}</h2>
                <p className="time">Time:{detailData.time && detailData.time.replace("T", " ")}</p>
                <p className="content" dangerouslySetInnerHTML={{ __html: detailData.content }}></p>
                {/* <img className="img" src={getImgUrl(detailData.imgUrl)} alt="" />
                <p className="author">{detailData.author}</p>
                <p className="bottom-time">
                    {detailData.time && detailData.time.replace("T", " ")}
                </p> */}
                <p className="close-btn">
                    <span
                        onClick={() => {
                            window.close();
                        }}
                    >
                        [ closed ]
                    </span>
                </p>
            </div>
        </div>
    );
}

export default NewsDetail;
