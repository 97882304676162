import React, { useEffect, useState } from 'react';
import { get } from 'utils/request';
import { getImgUrl } from 'utils/utils';
import { HOME_PRODUCT_CLASSIFY } from 'pages/Product/constants.js'

import './index.less';

function HomeProductDetail(props) {
  const { type } = props
  const [list, setList] = useState([])

  useEffect(() => {
    get(`/v2/product/home/classify/${type}?page=1&limit=6`)
    .then(data => {
      const { res } = data || {}
      const { items } = res || {}
      setList(items)
    })
  }, [])

  return (
      <div className="home-product-detail">
          <div className="product-detail-title">
            {HOME_PRODUCT_CLASSIFY[type].name} - {HOME_PRODUCT_CLASSIFY[type].homeDesc}
            <span className="right">
                <a href={`/Product?type=${type}`}>View More &gt;</a>
            </span>
          </div>
          <div>
            <div className="big-img">
              <a href={`/Product?type=${type}`}>
                <img src={HOME_PRODUCT_CLASSIFY[type].pic} />
              </a>
            </div>
            <div className="right">
              <ul>
                {list && list.length>0 && list.map((item,index)=>{
                  return <li key={index}>
                    <a href={'/ProductDetail/' + item.id} className='product-title'>
                      { item.pic && <img className='home-product-pic' src={getImgUrl(item.pic)} alt='' />}
                      <h5 title={item.name} className="l1epsc pro-name"> {item.name}</h5>
                      <p className="l1epsc">CAS Number: {item.cas}</p>
                      <p className="l1epsc">Molecular Formula: {item.formula}</p>
                    </a>
                  </li>
                })}
              </ul>
            </div>
          </div>
      </div>
  );
}

export default HomeProductDetail;