import React, { useState, useEffect } from "react";

export default function ProductIntroduction() {

    const content = `<div>
    <p style="text-indent:2em;"><span style="background-color:#ffffff;"><span style="font-size:24px;"><span style="color:#000000;">Contact</span></span></span> <span style="background-color:#ffffff;"><span style="font-size:24px;"><span style="color:#ff5010;">Details</span></span></span></p>
    </br>
    <p style="text-indent:2em;"><span style="background-color:#ffffff;"><span style="font-size:16px;"><span >Address:No.200 Zhenhua Rd.Xihu Industrial Park,Hangzhou 310030, China</span></span></span></p>
    </br>
    <p style="text-indent:2em;"><span style="background-color:#ffffff;"><span style="font-size:16px;"><span >Telephone:+86-571-87396430 / 87396432</span></span></span></p>
    </br>
    <p style="text-indent:2em;"><span style="background-color:#ffffff;"><span style="font-size:16px;"><span >Fax:+86-571-87396431</span></span></span></p>
    </br>
    <p style="text-indent:2em;"><span style="background-color:#ffffff;"><span style="font-size:16px;"><span >Email:</span></span></span></p>
    <p style="text-indent:2em;"><span style="background-color:#ffffff;"><span style="font-size:16px;"><span >For sales enquiries, please contact sales@jhechem.com</span></span></span></p>
    <p style="text-indent:2em;"><span style="background-color:#ffffff;"><span style="font-size:16px;"><span >For accounting questions,please contact accounting@jhechem.com</span></span></span></p>
    <p style="text-indent:2em;"><span style="background-color:#ffffff;"><span style="font-size:16px;"><span >For logistics questions,please contact Logistics@jhechem.com</span></span></span></p>
    </br>
    <p style="text-indent:2em;"><span style="background-color:#ffffff;"><span style="font-size:16px;"><span >Web: www.jhechem.com</span></span></span></p>
    </div>`
    return <div style={{width: '1136px',margin: '30px auto 0'}} >
        {/* <img style={{
            width:'80%',
            marginLeft: '35px'
        }} src="https://files.jhechem.com/20061-20201115150209649-jh-7.jpeg" /> */}
        <div style={{
            marginTop: '20px',
            position: 'relative',
            marginBottom: '90px'
        }}>
            <img style={{
                width:'500px',
                position: 'absolute',
                right: '0',
                top: '20px'
            }} src="https://files.jhechem.com/20061-20201115153219047-jh-map.jpg" />
            <div style={{
                width:'100%',
                color: '#777',
                fontSize: '16px',
                fontWeight: '400'
            }} dangerouslySetInnerHTML = {{ __html: content }}></div>
        </div>
    </div>
}
