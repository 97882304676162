import React, { useContext, useEffect, useState } from 'react';
import { currentContext } from 'App';
import { getFileContent } from 'utils/utils';
import './index.less';

function Common(props) {
    const curSites = useContext(currentContext);
    console.log('--- curSites', curSites)
    const {cover = {}} = curSites;
    const { directories = [] } = cover;
    const { type, title, subTitle } = props;
    const [content, setContent] = useState('')

    const curCommonData = directories.filter((item)=>{
        if(type===1){
            return item.isShowContent;
        }
        if(type===2){
            return !item.isShowContent;
        }
    }).filter((item)=>{
        return item.title ===title
    })[0];
    
    useEffect(()=>{
        console.log('--- curCommonData.isShowContent', curCommonData.isShowContent)
        if(!!curCommonData.isShowContent){
            console.log(curCommonData.content)
            getFileContent(curCommonData.content).then((res)=>{
                if(res.res){
                    setContent(res.res.content)
                }
            })
        }else{
            console.log(curCommonData.subDirectories)
            getFileContent(curCommonData.subDirectories.find(item=>item.title===subTitle).content).then((res)=>{
                if(res.res){
                    setContent(res.res.content)
                }
            })
        }
    },[])
    
    return (
        <div className='common-content'>
            {
                !!curCommonData.isShowContent && <div dangerouslySetInnerHTML = {{ __html: content }}></div>
            }{
                !curCommonData.isShowContent && <div className='sub-menu-container'>
                    <div className="side-sub-menu">
                        <div className='sub-title-item'>{title}</div>
                        {curCommonData.subDirectories.map((item)=>{
                            return <div className='sub-title-item' key={item.title}>
                                <a href={`/commonPage/2/${title}/${item.title}`}>{item.title}</a>
                            </div>
                        })}
                    </div>
                    <div className="sub-content" style={{fontWeight: 400}}>
                        <div dangerouslySetInnerHTML = {{ __html: content }}></div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Common;