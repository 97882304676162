import React from 'react';
import { Spin } from 'antd'
import { getImgUrl } from 'utils/utils';
import './index.less';

export default function ProductList(props) {
    const { list=[], type='style1', limit=null, loading= false } = props;
    
    if(loading){
        return <div style={{textAlign:'center'}}><Spin /></div>
    }
    return <ul className={'ProductList '+(type === 'style2'?'ProductListStyle2':'')}>
            {list.map((item, index) => {
                return <>
                {(limit && limit > index || !limit) && <li className={type} key={index}>
                    {type === 'style1' && <div>
                        <a href={'/ProductDetail/' + item.id} className='product-title'>{item.name}</a>
                        <div className='product-attrs'>
                            {item.attrs && item.attrs.length > 0 && item.attrs.map((attr, idx1) => {
                                return idx1<2 && <p className="l1epsc" key={idx1}>{attr.name}: {attr.value}</p>
                            })}
                        </div>
                        { item.pic && <img className='product-pic' src={getImgUrl(item.pic)} alt='' />}
                    </div>}
                    {type === 'style2' && <a href={'/ProductDetail/' + item.id}>
                        <img className='product-pic' src={getImgUrl(item.pic)} alt='' />
                        <div className='product-attrs'>
                            {item.attrs && item.attrs.length > 0 && item.attrs.map((attr, idx2) => {
                                return idx2<3 && <div key={idx2}>
                                    <p key={idx2}>{attr.name}: {attr.value}</p>
                                </div>
                            })}
                            <p>{item.name}</p>
                        </div>
                    </a>}
                </li>}
                </>
            })}
        </ul>
}