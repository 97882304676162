import React, { useState, useEffect, useContext } from 'react';
import { get } from 'utils/request'
import { sessionStorage } from 'utils/utils';
import { currentContext } from 'App';
import NewsList from 'components/NewsList';
import Title from 'elements/Title';
import './News.less';

function News() {
    const { id } = useContext(currentContext);
    // const limit = 20;
    const [listData, setListData] = useState([]);
    // const [page, setPage] = useState(1);
    useEffect(() => {
        if (id) {
            get('/v2/news/list/'+id, {
                // page: page,
                // limit: limit
            }).then(data => {
                if (data.code === 0 && data.res) {
                    setListData(data.res);
                }
            })
        }
    }, [id]);

    // function changePage(page) {
    //     setPage(page);
    // }
    return (
        <div className="NewsPage">
            <img style={{
                width: '300px',
                position: 'absolute',
                top: '30px',
                left: '40px'
            }} src="https://files.jhechem.com/20061-20201115154052771-jh-11.jpeg" />
            <div className="container-news-title">
                <Title cnTitle1 = '新闻' cnTitle2 = '动态' enTitle1 = 'News' enTitle2 = 'Events'/>
            </div>
            <NewsList list={listData} />
            <div className="pagination">
            {/* 总共有{listData.length}篇记录
            26 Records  */}
            {sessionStorage.get('siteName')==='中文'?`总共有${listData.length}篇记录`:`${listData.length} Records `}    
                {/* <span className='page-btn'>上一页</span>
                <span className='page-btn'>下一页</span> */}
            </div>
        </div>
    );
}

export default News;