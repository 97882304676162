import React from 'react';
import { sessionStorage } from 'utils/utils';
import './index.less';

function SiteChoose(props) {
    let { cnTitle1 = '' , cnTitle2 = '' , enTitle1 = '' , enTitle2 = '', position = 'center' } = props;
    return (
        <span className={'title-component-container ' + ('title-component-container-' + position)}>
            {sessionStorage.get('siteName')==='中文'?cnTitle1:enTitle1}
            <span className="tip">{ sessionStorage.get('siteName')==='中文'?cnTitle2:enTitle2}</span>
        </span>
    );
}

export default SiteChoose;