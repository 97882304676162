import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { currentContext } from 'App';
import Home from 'pages/Home';
import Common from 'pages/Common';
import News from 'pages/News/News.js';
import NewsDetail from 'pages/News/NewsDetail.js';
import SpecialsOffers from 'pages/Product/SpecialsOffers.js';
import Product from 'pages/Product/Product.js';
import ProductDetail from 'pages/Product/ProductDetail.js';
import ProductIntroduction from 'pages/Product/ProductIntroduction.js';
import ContactUs from 'pages/ContactUs/index.js';

function Router() {
    const { cover={} } = useContext(currentContext);
    const { directories=[] } = cover;

    function getCommonPageRouter(){
        //动态注册通用页面的路由
        let result=[];
        directories.length>0 && directories.map((item)=>{
            //只有一级目录
            if(item.isShowContent){
                result.push(<Route 
                    key={`/commonPage/1/${item.title}`}
                    path={`/commonPage/1/${item.title}`} 
                >
                    <Common type={1} title={item.title}/>
                </Route>)
            }else{
                //有子目录
                item.subDirectories.length >0 && item.subDirectories.map((subItem)=>{
                    result.push(<Route 
                        key={`/commonPage/2/${item.title}/${subItem.title}`} 
                        path={`/commonPage/2/${item.title}/${subItem.title}`} 
                    >
                        <Common type={2} title={item.title} subTitle={subItem.title}/>
                    </Route>)

                })
            }
        });
        return result;
    }
    return (
        <BrowserRouter>
            <Switch>
                {getCommonPageRouter()}
                <Route path="/News">
                    <News />
                </Route>
                <Route path="/NewsDetail/:id">
                    <NewsDetail />
                </Route>
                <Route path="/ProductDetail/:id">
                    <ProductDetail />
                </Route>
                <Route path="/ContactUs">
                    <ContactUs />
                </Route>
                <Route path="/ProductIntroduction">
                    <ProductIntroduction />
                </Route>
                <Route path="/SpecialsOffers">
                    <SpecialsOffers />
                </Route>
                <Route path="/Product">
                    <Product />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </BrowserRouter>
    );
}

export default Router;